<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
import { getAction } from '@/command/netTool'
export default {
  name: 'weChatMoneyDetail',
  data() {
    return {
      ...api.command.getState(),
      // 店铺搜索
      storeData: [],
      moduleListBySysAllList: [],
      moduleListBySysArr: [],
      detail: {
        minPrice: '',
        maxPrice: '',
        allPrice: '',
        maxNum: '',
        receiveDay: ''
      }
    }
  },
  watch: {
    'detail.usedSystem'(a, b) {
      if (a) {
        this.setData(a)
      }
    }
  },
  mounted() {
    this.getDataInit()
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/redEnvelop/info?id=${id}`
        })
        .then(result => {
          let { usedSeat } = result
          this.detail = {
            ...result,
            usedSeat: usedSeat ? usedSeat.split(',') : []
          }
          this.$forceUpdate()
        })

    // this.storeSearch()
  },
  methods: {
    setData(data = this.detail.usedSystem) {
      let arr = (
        this.moduleListBySysAllList.find(f => f.value == data) || {
          farmTurntableModule: []
        }
      ).farmTurntableModule
      let brr = arr.map(e => {
        return {
          name: e.name,
          value: e.code
        }
      })
      this.moduleListBySysArr = brr
    },
    getDataInit() {
      getAction('/api/turntableModule/moduleListBySysAll').then(res => {
        if (res.data && res.data.length) {
          this.moduleListBySysAllList = res.data.map(e => {
            return {
              farmTurntableModule: e.farmTurntableModule,
              name: e.sysName,
              value: e.sysCode
            }
          })
        }
      })
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '微信红包名称(12字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 12
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '红包金额',
            type: 'inputNumber',
            cols: 12,
            key: 'allPrice',
            props: {
              addonAfter: '元',
              min: 0.3
            },
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '发放方式',
            type: 'select',
            cols: 4,
            key: 'sendType',
            defaultValue: '1',
            typeData: [
              {
                name: '固定金额',
                value: '0'
              },
              {
                name: '随机金额',
                value: '1'
              }
            ],
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          ...[
            {
              name: '固定金额',
              type: 'inputNumber',
              cols: 8,
              key: 'maxPrice',
              display: this.detail.sendType == '0',
              props: {
                min: 0,
                addonAfter: '元'
              },
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            },
            {
              name: '随机金额',
              type: 'row',
              cols: 8,
              gutter: 10,
              display: this.detail.sendType == '1',
              rules: [
                {
                  required: true
                }
              ],
              children: [
                {
                  label: '最小值',
                  type: 'inputNumber',
                  cols: 12,
                  key: 'minPrice',
                  props: {
                    min: 0.3,
                    max: 50,
                    addonAfter: '元'
                  },
                  rules: [
                    {
                      required: true,
                      type: 'number'
                    }
                  ]
                },
                {
                  label: '最大值',
                  type: 'inputNumber',
                  cols: 12,
                  key: 'maxPrice',
                  props: {
                    min: 0.3,
                    max: 50,
                    addonAfter: '元'
                  },
                  rules: [
                    {
                      required: true,
                      type: 'number'
                    }
                  ]
                }
              ]
            }
          ].filter(e => e.display),
          {
            name: '红包有效期',
            type: 'row',
            cols: 12,
            gutter: 10,
            rules: [
              {
                required: true
              }
            ],
            children: [
              {
                label: '开始日期',
                type: 'datePicker',
                cols: 12,
                key: 'startDate',
                props: {
                  mode: 'date',
                  format: 'YYYY-MM-DD',
                  valueFormat: 'YYYY-MM-DD',
                  placeholder: '请选择开始日期'
                },
                rules: [
                  {
                    required: true,
                    type: 'date'
                  }
                ]
              },
              {
                label: '结束日期',
                type: 'datePicker',
                cols: 12,
                key: 'endDate',
                props: {
                  mode: 'date',
                  format: 'YYYY-MM-DD',
                  valueFormat: 'YYYY-MM-DD',
                  placeholder: '请选择结束日期'
                },
                rules: [
                  {
                    required: true,
                    type: 'date'
                  }
                ]
              }
            ]
          },
          {
            name: '备注',
            type: 'input',
            cols: 12,
            key: 'remark',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '是否为抽奖红包',
            type: 'radioButton',
            cols: 12,
            key: 'drawRed',
            typeData: [
              {
                name: '否',
                value: '0'
              },
              {
                name: '是',
                value: '1'
              }
            ],
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        title: '领取限制',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '领取间隔时间（日）',
            type: 'inputNumber',
            cols: 12,
            key: 'receiveDay',
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '最大领取次数（输入0代表无限制）',
            type: 'inputNumber',
            cols: 12,
            key: 'maxNum',
            props: {
              addonAfter: '张',
              min: 0
            },
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          }
        ]
      }
    },
    getForm3() {
      return {
        title: '中奖设置',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '系统（单选）',
            type: 'radioButton',
            cols: 24,
            key: 'usedSystem',
            typeData: this.moduleListBySysAllList,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ],
            onChange: () => {
              this.detail.usedSeat = []
            }
          },
          {
            name: '可抽奖位置（多选）',
            type: 'checkBoxButton',
            cols: 24,
            key: 'usedSeat',
            typeData: this.moduleListBySysArr,
            rules: [
              {
                required: true,
                type: 'array'
              }
            ]
          },
          {
            name: '适用主体',
            type: 'row',
            cols: 24,
            children: [
              {
                label: '适用主体',
                type: 'radioButton',
                key: 'bodyStatus',
                cols: 4,
                typeData: [
                  {
                    name: '所有主体',
                    value: '0'
                  },
                  {
                    name: '自定义主体',
                    value: '1'
                  }
                ]
              }
            ]
          }
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            if (data.sendType == '1' && !(data.minPrice < data.maxPrice)) {
              this.$message.warning('随机金额中最小金额应小于最大金额！')
              return
            }
            if (moment(data.startDate).unix() > moment(data.endDate).unix()) {
              this.$message.warning('红包有效期填写不正确！')
              return
            }
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: `/redEnvelop/${data.id ? 'edit' : 'save'}`,
                params: {
                  ...data,
                  usedSeat: data.usedSeat.toString()
                },
                isPost: false
              })
              .then(result => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/weChatMoney')
        }
      ]
      const right = [
        {
          name: this.detail.enabled == 0 ? '上架' : '下架',
          onClick: () => {
            api.command.edit
              .call(this, {
                url: `/redEnvelop/${this.detail.enabled == 0 ? 'enable' : 'disable'}/${this.detail.id}`,
                isPost: false
              })
              .then(() => {
                this.$router.push('/marketingCenter/weChatMoney')
              })
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPut.call(this, {
              url: `/redEnvelop/delete/${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/weChatMoney')
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
